import { findIndex, isEmpty } from "lodash";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { autofill, reduxForm, reset, stopSubmit, touch } from "redux-form";

import { withAppUser, withNotifier } from "@dpdgroupuk/mydpd-app";
import {
  withLoader,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";

import {
  DELETE_ALL_CONFIRMATION_POP_UP,
  DELETE_CONFIRMATION_POP_UP,
  PRODUCT_BOOK_ENTRY_PAGE,
} from "~/constants/analytics";
import { PRODUCT_COLUMNS } from "~/constants/columns";
import COUNTRIES from "~/constants/enums/countries";
import CURRENCIES from "~/constants/enums/currencies";
import {
  BOOK_SEARCH_FORM,
  PRODUCT_FORM,
  ProductBookEntity,
  ProductBookFilterOptionsList,
  SEARCH_CRITERIA_VALUE,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import withCommodityCodeSearch from "~/hocs/withCommodityCodeSearch";
import withImportsProductBook from "~/hocs/withImportsProductBook";
import withLoaderHandlers from "~/hocs/withLoaderHandlers";
import withProductBookFormAnalytics from "~/hocs/withProductBookFormAnalytics";
import withPromptAnalytics from "~/hocs/withPromptAnalytics";
import { validateProductUnitValue } from "~/models/validators/additionalValidations";
import productSchema from "~/models/validators/productSchema";
import { trimEntityFields } from "~/pages/ProductBook/models";
import { UmsSelectors } from "~/redux";
import { ProductActions, ProductBookSelectors } from "~/redux/orm";
import { ReferenceDataActions } from "~/redux/reference";
import { CREATE_PRODUCT, PRODUCTS } from "~/router";
import { getErrorMessage } from "~/utils/error";
import createValidator from "~/utils/joiReduxForm";
import { roundToDecimal } from "~/utils/numbers";
import { getDeepKeys } from "~/utils/object";
import { getPathId, omitLocationSearch, parseQuery } from "~/utils/query";

import ProductPage from "./components/ProductPage";
import {
  getInitialProductState,
  getSearchQuery,
  isProductBookReadonly,
} from "./models";
import {
  getCountriesKeyValue,
  getCountryCode,
  getCurrenciesKeyValue,
  getProductBooksTotalResults,
  getProductRequiredFields,
  getSearchFormErrors,
  getSelectedCreateCountry,
  getSelectedCurrency,
  isUserReadonlyPermissions,
  isUserReadonlyProductBookPermissions,
} from "./selectors";

export default compose(
  withPrompt,
  withPromptAnalytics,
  withAppUser,
  withOverlay,
  withImportsProductBook,
  withNotifier,
  withSnackbar,
  connect(state => ({
    searchFormErrors: getSearchFormErrors(state),
  })),
  connect(
    (state, { match, location }) => {
      const queryStringValues = parseQuery(location).values;
      const productBookId = getPathId(match, "params.id");
      const product = ProductBookSelectors.getProductBook(state, productBookId);
      const preferences = UmsSelectors.getPreferences(state);
      const currencies = getCurrenciesKeyValue(state);
      const countries = getCountriesKeyValue(state);

      const isEdit = productBookId && location.pathname !== CREATE_PRODUCT;
      const title = isEdit ? S.EDIT_PRODUCT : S.CREATE_PRODUCT;
      const initialValues = isEdit
        ? {
            ...getInitialProductState(
              product,
              queryStringValues,
              currencies,
              countries
            ),
            unitValue: roundToDecimal(product?.unitValue),
            unitWeight: roundToDecimal(product?.unitWeight),
          }
        : { countryOfOrigin: COUNTRIES.GB, currency: CURRENCIES.GBP };
      const isReadonly = isEdit
        ? isProductBookReadonly(product, preferences)
        : isUserReadonlyProductBookPermissions(state);

      return {
        initialValues,
        isEdit,
        isReadonly,
        title,
        currencies,
        countries,
        records: ProductBookSelectors.getProductBooks(state),
        totalResults: getProductBooksTotalResults(state),
        requiredFields: getProductRequiredFields(),
        countryCode: getCountryCode(state),
        isUserReadonlyPermissions: isUserReadonlyPermissions(state), // TODO check it and check it on addressbook
        selectedCountry: getSelectedCreateCountry(state),
        selectedCurrency: getSelectedCurrency(state),
        isDisabled: !productBookId,
        trackProps: {
          openImportModal: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_IMPORT,
          onClickRow: PRODUCT_BOOK_ENTRY_PAGE.SELECT_ROW,
          onSearchTextChange: PRODUCT_BOOK_ENTRY_PAGE.ENTER_TEXT_SEARCH,
          onSearchFieldChange:
            PRODUCT_BOOK_ENTRY_PAGE.ON_SELECT_SEARCH_CRITERIA,
          onFirst: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_FIRST,
          onLast: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_LAST,
          onNext: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_NEXT,
          onPrevious: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_PREV,
          onClickDeleteAll: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_DELETE_ALL,
          onClickNew: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_NEW,
          onOpenColumnPopover: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_COLUMN_HEADER,
          onChangeColumnsSelection:
            PRODUCT_BOOK_ENTRY_PAGE.ON_SELECT_COLUMN_HEADER_ITEM,
        },
      };
    },
    (
      dispatch,
      {
        location,
        snackbar,
        history,
        match,
        overlay,
        notifier,
        analyticsPrompt,
        searchFormErrors,
      }
    ) => {
      const queryStringValues = parseQuery(location).values;
      const searchProducts = async fetchOptions => {
        if (isEmpty(searchFormErrors)) {
          dispatch(reset(PRODUCT_FORM));

          const queryRequest = getSearchQuery(location);
          try {
            return await dispatch(
              ProductActions.searchProducts(queryRequest, fetchOptions)
            );
          } catch (e) {
            dispatch(
              stopSubmit(BOOK_SEARCH_FORM, {
                SEARCH_CRITERIA_VALUE: getErrorMessage(e, S.PRODUCTS),
              })
            );
            dispatch(touch(BOOK_SEARCH_FORM, SEARCH_CRITERIA_VALUE));
          }
        } else {
          dispatch(touch(BOOK_SEARCH_FORM, SEARCH_CRITERIA_VALUE));
        }
      };

      return {
        searchProducts,
        fetchCountries: notifier.runAsync(
          () => dispatch(ReferenceDataActions.fetchCountries()),
          { entityName: S.COUNTRIES }
        ),
        fetchCurrencies: notifier.runAsync(
          () => dispatch(ReferenceDataActions.fetchCurrencies()),
          {
            entityName: S.CURRENCY,
          }
        ),
        onCountryChange: ({ value }) =>
          dispatch(
            autofill(PRODUCT_FORM, ProductBookEntity.COUNTRY_OF_ORIGIN, value)
          ),
        onClickNew: () => {
          dispatch(reset(PRODUCT_FORM));
          history.push({
            pathname: CREATE_PRODUCT,
            search: omitLocationSearch(location.search, ["values"]),
          });
        },
        deleteAll: async () => {
          overlay.show();
          try {
            await dispatch(ProductActions.deleteAllProductBook());
            await searchProducts();
            history.push({
              pathname: PRODUCTS,
              search: omitLocationSearch(location.search, ["values"]),
            });
          } finally {
            overlay.hide();
          }
        },
        onDeleteClick: ({ initialValues }) =>
          analyticsPrompt.showConfirmationDelete({
            header: S.CONFIRM_PRODUCT_DELETION,
            message: S.ARE_YOU_SURE_TO_DELETE_PRODUCTBOOK_ENTRY,
            footer: S.THERE_IS_NO_WAY_TO_RECOVER_PRODUCTBOOK,
            trackProps: DELETE_CONFIRMATION_POP_UP, // TODO add analytics later
            closeButtonText: S.CANCEL,
            confirmButtonText: S.CONFIRM,
            onConfirm: notifier.runAsync(async () => {
              await dispatch(
                ProductActions.deleteProductBookById(
                  getPathId(match, "params.id")
                )
              );

              setTimeout(() => {
                const shortName =
                  initialValues.shortName.length > 30
                    ? initialValues.shortName.substr(0, 30) + "..."
                    : initialValues.shortName;

                snackbar.showSuccess({
                  message: `Your product (${shortName}) has been deleted successfully`,
                });

                // TODO: add analytics for DELETE_CONFIRMED_POP_UP
              }, 0);

              history.push({
                pathname: PRODUCTS,
                search: omitLocationSearch(location.search, ["values"]),
                state: {
                  forceReload: true,
                },
              });
            }),
          }),
        onSubmit: notifier.runAsync(
          async (values, dispatch, { overlay, history, location }) => {
            try {
              overlay.show();
              const { productBookId } = values;
              const prepareData = trimEntityFields(values);
              if (productBookId) {
                await dispatch(
                  ProductActions.updateProductBook(productBookId, prepareData)
                );
              } else {
                await dispatch(ProductActions.createProductBook(prepareData));
                dispatch(reset(PRODUCT_FORM));
              }

              history.push({
                pathname: PRODUCTS,
                search: omitLocationSearch(location.search, ["values"]),
                state: {
                  forceReload: true,
                },
              });

              snackbar.showSuccess({
                message: productBookId
                  ? S.EDIT_PRODUCT_BOOK_WAS_UPDATED
                  : S.EDIT_PRODUCT_BOOK_WAS_CREATED,
              });
            } finally {
              overlay.hide();
            }
          }
        ),
        shouldAsyncValidate: params => {
          if (!params.syncValidationPasses) {
            return false;
          }
          switch (params.trigger) {
            case "blur":
            case "change":
              // blurring or changing
              return true;
            case "submit":
              // submitting, so only async validate if form is dirty or was never initialized
              // conversely, DON'T async validate if the form is pristine just as it was
              // initialized
              return (
                !!queryStringValues || !params.pristine || !params.initialized
              );
            default:
              return false;
          }
        },
      };
    }
  ),
  withProps(
    ({
      analyticsPrompt,
      notifier,
      deleteAll,
      snackbar,
      history,
      location,
      isEdit,
      records,
      match,
    }) => ({
      ...(isEdit && {
        initialSelectedRowIds: {
          [findIndex(records, row => row.productBookId === match.params.id)]:
            true,
        },
      }),
      onClickDeleteAll: () =>
        analyticsPrompt.showConfirmationDelete({
          header: S.CONFIRM_PRODUCT_BOOK_DELETION,
          message: S.ARE_YOU_SURE_TO_DELETE_ALL_PRODUCTBOOK,
          footer: S.THERE_IS_NO_WAY_TO_RECOVER_PRODUCTBOOK,
          trackProps: DELETE_ALL_CONFIRMATION_POP_UP, // TODO add analytics later
          closeButtonText: S.CANCEL,
          confirmButtonText: S.CONFIRM,
          onConfirm: notifier.runAsync(async () => {
            await deleteAll().then(() => {
              snackbar.showSuccess({ message: S.PRODUCTS_DELETED });
              history.push({
                pathname: PRODUCTS,
              });
            });
          }),
        }),
      onClickRow: async row => {
        const {
          original: { productBookId },
        } = row;
        history.push({
          pathname: `${PRODUCTS}/${productBookId}`,
          search: omitLocationSearch(location.search, ["values"]),
        });
      },
      columns: PRODUCT_COLUMNS,
      columnOrder: [
        ProductBookEntity.SHORT_NAME,
        ProductBookEntity.PRODUCT_CODE,
        ProductBookEntity.COMMODITY_CODE,
        ProductBookEntity.UNIT_VALUE,
      ],
      filterOptionsList: ProductBookFilterOptionsList,
    })
  ),
  reduxForm({
    form: PRODUCT_FORM,
    shouldError: () => true,
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: (values, props) =>
      createValidator(productSchema, [() => validateProductUnitValue(values)])(
        values,
        props
      ),
    onSubmitFail: (errors, dispatch, _, props) => {
      const mappedErrors = getDeepKeys(errors);
      props?.notifier.scrollToError(mappedErrors);
    },
  }),
  withProductBookFormAnalytics,
  withLoaderHandlers,
  withLoader({
    loadFn: ({ fetchCountries, fetchCurrencies }) =>
      Promise.all([fetchCountries(), fetchCurrencies()]),
  }),
  withCommodityCodeSearch,
  withTrack(trackProps(PRODUCT_BOOK_ENTRY_PAGE))
)(ProductPage);
